<template>
  <b-container fluid>
    <b-row class="mb-4 justify-content-between">
      <b-col cols="12" xl="8" md="6" class="mb-2">
        <div class="input-group">
          <span class="input-group-text input-group-text--addon-left">
            <b-icon icon="search"></b-icon>
          </span>

          <input
            v-model="filters.name"
            type="text"
            class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
            placeholder="搜索"
          />
        </div>
      </b-col>
    </b-row>

    <TableData :items="paginatedData" :headers="headers" :isLoading="isLoading">
      <template v-slot:custom-couponUsedTime="{ item }">
        <span style="color:#AAAAAA;" v-if="item.couponUsedTime"> {{ item.couponUsedTime | formatDate }}</span>
        <span v-else>-</span>
      </template>

      <template v-slot:custom-assignedDate="{ item }">
        {{ item.assignedDate | formatDate }}
      </template>
      <template v-slot:custom-couponExpiredTime="{ item }">
        {{ item.couponExpiredTime | formatDate }}
      </template>
      <template v-slot:custom-couponStatus="{ item }">
        <div v-if="item.couponStatus === 'ACTIVE'" class="text-success">
          啟用
        </div>
        <div v-else style="color:#AAAAAA;">已使用</div>
      </template>
    </TableData>

    <Pagination
      v-if="!isLoading && paginatedData.length"
      v-model="currentPage"
      :list-data="filterCustomer"
      :limit="limit"
    />
  </b-container>
</template>

<script>
import couponService from '../../../services/coupon.service'
import Pagination from '../../Pagination.vue'
import TableData from '../../TableData.vue'

export default {
  name: 'LoyaltyPrograms',
  data: () => ({
    customerList: [],
    currentPage: 1,
    limit: 10,

    filters: {
      name: '',
      start: '',
      end: ''
    },

    headers: [
      { text: '使用者身分', value: 'customerId' },
      { text: '會員編號', value: 'posMemberId' },
      { text: '姓名', value: 'name' },
      { text: '電話號碼', value: 'phoneNumber' },
      { text: '提供日期', value: 'assignedDate' },
      { text: '優惠券狀態', value: 'couponStatus' },
      { text: '使用日期', value: 'couponUsedTime' },
      { text: '優惠券到期日', value: 'couponExpiredTime' }
    ],

    breadcrumbItems: [
      { label: '優惠券管理', to: '/loyalty-programs' },
      { label: '已使用的客戶' }
    ],

    isLoading: false,
    isSubmitting: false,
    isSending: false,
    isSelectAll: false,
    customerIds: []
  }),

  components: {
    Pagination,
    TableData
  },

  async mounted () {
    await this.getAllCustomers()
  },

  computed: {
    filterCustomer () {
      return this.customerList
        .filter((item) =>
          this.filters.name.length
            ? item?.name
              ?.toLowerCase()
              .replace(/\s+/g, '')
              .includes(
                this.filters.name.toLowerCase().replace(/\s+/g, '')
              ) ||
              item?.posMemberId
                ?.toLowerCase()
                .replace(/\s+/g, '')
                .includes(
                  this.filters.name.toLowerCase().replace(/\s+/g, '')
                ) ||
              item?.id
                ?.toString()
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(
                  this.filters.name.toLowerCase().replace(/\s+/g, '')
                ) ||
              item?.phoneNumber
                ?.toString()
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(this.filters.name.toLowerCase().replace(/\s+/g, ''))
            : true
        )
        .sort((a, b) => +new Date(b.joinDate) - +new Date(a.joinDate))
    },

    paginatedData () {
      return this.filterCustomer.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    }
  },
  watch: {
    filterCustomer () {
      this.currentPage = 1
    }
  },
  methods: {
    async getAllCustomers () {
      this.isLoading = true
      // const resData = await couponService.getCouponHistory( this.$route.params.id )
      const resData = await couponService.getCustomerUsingCouponById(
        this.$route.params.id
      )
      this.customerList = resData?.map((item) => ({
        ...item.customerCouponViewDTO,
        couponExpiredTime: item.customerCouponViewDTO.expiryDate,
        ...item.customerDTO,
        couponUsedTime: item.couponUsedTime
      }))
      this.isLoading = false
    }
  }
}
</script>
