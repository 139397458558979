var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mb-4 justify-content-between"},[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","xl":"8","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('span',{staticClass:"input-group-text input-group-text--addon-left"},[_c('b-icon',{attrs:{"icon":"search"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.name),expression:"filters.name"}],staticClass:"form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px",attrs:{"type":"text","placeholder":"搜索"},domProps:{"value":(_vm.filters.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "name", $event.target.value)}}})])])],1),_c('TableData',{attrs:{"items":_vm.paginatedData,"headers":_vm.headers,"isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"custom-couponUsedTime",fn:function(ref){
var item = ref.item;
return [(item.couponUsedTime)?_c('span',{staticStyle:{"color":"#AAAAAA"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.couponUsedTime)))]):_c('span',[_vm._v("-")])]}},{key:"custom-assignedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.assignedDate))+" ")]}},{key:"custom-couponExpiredTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.couponExpiredTime))+" ")]}},{key:"custom-couponStatus",fn:function(ref){
var item = ref.item;
return [(item.couponStatus === 'ACTIVE')?_c('div',{staticClass:"text-success"},[_vm._v(" 啟用 ")]):_c('div',{staticStyle:{"color":"#AAAAAA"}},[_vm._v("已使用")])]}}])}),(!_vm.isLoading && _vm.paginatedData.length)?_c('Pagination',{attrs:{"list-data":_vm.filterCustomer,"limit":_vm.limit},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }